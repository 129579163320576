import React, { useState, useContext } from "react"
import { css } from "@emotion/core"
import styled from "@emotion/styled"
import MainLayout from "../layouts/Main"
import Header from "components/Common/Header"
import PageMetadata from "../components/Common/PageMetadata"
import FeaturedArticlesSidebar from "../components/FeaturedArticlesSidebar"
import getFeaturedArticles from "../utils/getFeaturedArticles"
import { graphql } from "gatsby"
import ColumnTitle from "components/UI/ColumnTitle"
import LightBox from "components/UI/LightBox"
import Button from "components/UI/Button"
import t from "theme"
import FirebaseContext from "components/Common/FirebaseContext"
import Slider from "components/UI/Slider"
import BeatLoader from "react-spinners/BeatLoader"
import { CardWrapper } from "components/UI/Card"

//uses GraphQL back reference - which automatically links articles back to company
export const query = graphql`
    query($ticker: String!) {
        selectedCompany: contentfulCompany(ticker: { eq: $ticker }) {
            ...CompanyFields
        }
    }
`

const startingAssumptions = [
    {
        uid: "customersSmall",
        title: "Customers <$100,000",
        currentRange: [800000, 1200000],
        fullRange: [100000, 2000000],

        ascend: true,
        target: "Summary!G63",
    },
    {
        uid: "customersBig",
        title: "Customers >$100,000",
        currentRange: [4000, 6000],
        fullRange: [1000, 10000],
        ascend: true,
        target: "Summary!G64",
    },
    {
        uid: "customersSmallARPC",
        title: "Customers <$100,000 ARPC CAGR",
        currentRange: [0.05, 0.15],
        fullRange: [0.01, 0.3],
        ascend: true,
        target: "Summary!G71",
    },
    {
        uid: "customersBigARPC",
        title: "Customers >$100,000 ARPC CAGR",
        currentRange: [0.15, 0.25],
        fullRange: [0.01, 0.3],
        ascend: true,
        target: "Summary!G72",
    },
    {
        uid: "discountRate",
        title: "Discount Range",
        currentRange: [0.08, 0.12],
        fullRange: [0.05, 0.2],
        ascend: false,
        target: "Summary!G78",
    },
]

const ValuationPage = ({ pageContext: { commonData, ticker }, data: { selectedCompany }, location }) => {
    const [loading, setLoading] = useState(false)
    const [ourSession, setOurSession] = useState(false)
    const [assumptions, setAssumptions] = useState([...startingAssumptions])
    const iframeUrl = "https://disruptionresearch.firebaseapp.com"

    /*
        Object.fromEntries(
            Object.entries(startingAssumptions).map((startingAssumption, index) => [
                startingAssumption[0],
                startingAssumption[1]["default"],
            ])
        )
  */

    const [theMessage, setTheMessage] = useState(``)
    const firebase = useContext(FirebaseContext)

    async function GetGoogleData(data) {
        setLoading(true)

        var googleTrial = firebase.functions().httpsCallable("DRvaluation")

        await googleTrial(data).then(function(result) {
            console.log("original message is " + result.data.message)
            setTheMessage(result.data.message)
            setOurSession(result.data.firstSession)
            console.log("FIRST MESSAGE IS: " + theMessage)
        })
        setLoading(false)
    }

    const featuredArticles = getFeaturedArticles(commonData, ticker)
    return (
        <MainLayout
            header={
                <Header
                    commonData={commonData}
                    selectedCompany={selectedCompany}
                    selectedFeature={`VALUATION`}
                    pathname={location.pathname}
                />
            }
            sidebar={<FeaturedArticlesSidebar title={"FEATURED ARTICLES"} featureds={featuredArticles} />}>
            <PageMetadata
                pageTitle={selectedCompany.shortName + " VALUATION"}
                pageDescription={"VALUATION on " + selectedCompany.shortName}
                pagePathname={location.pathname}
            />

            <ColumnTitle>{"VALUATION OVERVIEW OF " + selectedCompany.shortName.toUpperCase()}</ColumnTitle>

            {startingAssumptions.map((startingAssumption, index) => (
                <CardWrapper
                    shadow
                    hover={false}
                    css={css`
                        margin-bottom: ${t.space[3]};
                    `}>
                    <h5
                        css={css`
                            margin-bottom: ${t.space[3]};
                        `}>
                        {startingAssumption["title"]}
                    </h5>
                    <Slider
                        id={startingAssumption["uid"]}
                        minMaxRange={startingAssumption["fullRange"]}
                        startingRange={startingAssumption["currentRange"]}
                        parentState={assumptions}
                        setParentState={setAssumptions}
                    />
                    <div
                        css={css`
                            width: 100%;
                            height: ${t.space[5]};
                        `}
                    />
                </CardWrapper>
            ))}

            {/*
            <Button
                onClick={() =>
                    GetGoogleData({
                        firstVal: sliderValues[0],
                        secondVal: sliderValues[1],
                        returnedSession: ourSession ? ourSession : false,
                    })
                }>
                REFRESH DATA
            </Button>*/}
            {/*
            <LightBox toggleComponent={<Button>Open Iframe</Button>}>
                <iframe
                    src={iframeUrl}
                    frameborder="0"
                    allowFullScreen
                    style={{
                        width: "80vw",
                        height: "80vh",
                    }}
                />
                </LightBox> */}

            <Button
                css={css`
                    width: 100%;
                    font-size: ${t.font.size[6]};
                    margin-bottom: ${t.space[5]};
                `}
                onClick={() => GetGoogleData({ assumptions: assumptions, returnedSession: ourSession })}>
                {loading ? <BeatLoader size={15} color={t.color.subtlegrey} /> : "ESTIMATE FAIR VALUE"}
            </Button>

            <div>{loading ? null : theMessage ? "Fair Value ($MM): " + theMessage : null}</div>
        </MainLayout>
    )
}

export default ValuationPage

/*
<Slider
mode={1}
step={5}
domain={domain}
onChange={values => setSliderValues(values)}
values={sliderValues}
css={css`
    position: relative;
    width: 100%;
    margin-bottom: ${t.space[10]};
`}>
<Rail>{({ getRailProps }) => <SliderRail getRailProps={getRailProps} />}</Rail>
<Handles>
    {({ handles, getHandleProps }) => (
        <div className="slider-handles">
            {handles.map(handle => (
                <Handle
                    key={handle.id}
                    handle={handle}
                    domain={domain}
                    getHandleProps={getHandleProps}
                />
            ))}
        </div>
    )}
</Handles>
<Tracks left={false} right={false}>
    {({ tracks, getTrackProps }) => (
        <div className="slider-tracks">
            {tracks.map(({ id, source, target }) => (
                <Track key={id} source={source} target={target} getTrackProps={getTrackProps} />
            ))}
        </div>
    )}
</Tracks>
<Ticks count={10}>
    {({ ticks }) => (
        <div className="slider-ticks">
            {ticks.map(tick => (
                <Tick key={tick.id} tick={tick} count={ticks.length} />
            ))}
        </div>
    )}
</Ticks>
</Slider>

<Button
onClick={() =>
    GetGoogleData({
        firstVal: sliderValues[0],
        secondVal: sliderValues[1],
        returnedSession: ourSession ? ourSession : false,
    })
}>
REFRESH DATA
</Button>
<div>
{loading
    ? "IT'S LOADING..."
    : theMessage
    ? theMessage.values.map((value, index) => <div>{value}</div>)
    : null}
</div>

*/
