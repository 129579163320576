import React, { useState } from "react"
import ReactSlider from "react-slider"
import { css } from "@emotion/core"
import styled from "@emotion/styled"
import t from "theme"

function formatNumber(num) {
    return num < 1 ? Math.round(num * 100).toString() + "%" : num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
}

const StyledSlider = styled(ReactSlider)`
    width: 100%;
    height: 2.25rem;
    touch-action: none;
`

const StyledThumb = styled.div`
    box-sizing: border-box;
    border: none;
    padding: 0.375em;
    width: 2.25rem;
    height: 2.25rem;
    border-radius: 50%;
    outline: none;
    box-shadow: 0 0.375em 0.5em -0.125em #808080, inset 0 -0.25em 0.5em -0.125em #bbb;
    background: linear-gradient(#c5c5c5, #efefef) content-box, linear-gradient(#fdfdfd, #c4c4c4) border-box;
    text-align: center;
    cursor: ew-resize;
`

const Thumb = (props, state) => (
    <StyledThumb {...props}>
        <div
            css={css`
                position: relative;
                top: 140%;
            `}>
            <span
                css={css`
                    margin-left: -100%;
                    margin-right: -100%;
                    text-align: center;
                `}>
                {formatNumber(state.valueNow)}
            </span>
        </div>
    </StyledThumb>
)

const StyledTrack = styled.div`
    top: 0;
    bottom: 0;
    background: ${props =>
        props.index === 2
            ? "linear-gradient(#f5f5f5, #eee 35%, #bfbfbf)"
            : props.index === 1
            ? "linear-gradient(" + t.color.light + ", " + t.color.primary + ") no-repeat"
            : "linear-gradient(#f5f5f5, #eee 35%, #bfbfbf)"};
    padding: 0.375em;
    border-radius: calc(2rem / 2);
    box-shadow: inset 0 -1px 1px #fff, inset 0 0.5em 0.5em #c4c4c4, inset 0 0 0 0.375em #efefef,
        inset 0 1px 0.125em 0.375em black, inset 0 0 0 1px #eee;
`

const Track = (props, state) => <StyledTrack {...props} index={state.index} />

const Slider = ({ id, minMaxRange, startingRange, parentState, setParentState, ...props }) => {
    return (
        <StyledSlider
            onAfterChange={values =>
                setParentState(
                    parentState.map(assumption =>
                        assumption.uid === id ? { ...assumption, currentRange: values } : assumption
                    )
                )
            }
            min={minMaxRange[0]}
            max={minMaxRange[1]}
            step={minMaxRange[1] > 1 ? 1 : 0.01}
            defaultValue={startingRange}
            renderTrack={Track}
            renderThumb={Thumb}
            {...props}
        />
    )
}

export default Slider

/*

const StyledSlider = styled(ReactSlider)`
    width: 100%;
    height: 25px;
`

const StyledThumb = styled.div`
    height: 25px;
    line-height: 25px;
    width: 25px;
    text-align: center;
    background-color: #000;
    color: #fff;
    border-radius: 50%;
    cursor: grab;
`

const Thumb = (props, state) => <StyledThumb {...props}>{state.valueNow}</StyledThumb>

const StyledTrack = styled.div`
    top: 0;
    bottom: 0;
    background: ${props => (props.index === 2 ? "#f00" : props.index === 1 ? "#0f0" : "#ddd")};
    border-radius: 999px;
`

const Track = (props, state) => <StyledTrack {...props} index={state.index} />

const Slider = () => <StyledSlider defaultValue={[50, 75]} renderTrack={Track} renderThumb={Thumb} />

export default Slider


*/
